import Slider from 'react-slick';
import Section from '../Section';
import styles from './Awards.module.scss';
import './AwardSlider.scss';

import award from './images/award.png';
import award0 from './images/award0.png';
import award1 from './images/award1.svg';
import award2 from './images/award2.png';
import award3 from './images/award3.png';
import award4 from './images/award4.png';
import award5 from './images/award5.png';
import award6 from './images/award6.png';
import award7 from './images/award7.png';
import award8 from './images/award8.png';

const SETTINGS = {
  dots: true,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
  speed: 200,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Awards = () => (
  <Section
    id="Awards"
    className={styles.Awards}
  >
    <div className="container pb-lg-5">
      <div className="row pb-lg-5">
        <div className="col-lg-12 text-center">
          <h1>
            UK National Cyber Awards
            <br />
            AI Cyber Product of the year 2023
          </h1>
          <img
            src={award}
            alt="The National Cyber Awards"
            className="py-5"
            style={{ maxWidth: '100%' }}
          />
          <p className={styles.Intro}>
            Multi-Award-winning cybersecurity platform
            <br />
            built on cutting-edge movement analysis AI by Cursor Insight
          </p>
        </div>
      </div>
      <div className="row">
        <Slider {...SETTINGS} className="AwardSlider">
          <div>
            <div className={styles.AwardInner}>
              <img src={award0} alt="" />
            </div>
            <p>Winner of SigWiComp by the German Research Center for AI</p>
          </div>
          <div>
            <div className={styles.AwardInner}>
              <img src={award1} alt="" />
            </div>
            <p>Remote Monitoring Security Solution of the Year</p>
          </div>
          <div>
            <div className={styles.AwardInner}>
              <img src={award2} alt="" />
            </div>
            <p>Winner of the Mouse Dynamics Challenge Competition of Balabit</p>
          </div>

          <div>
            <div className={styles.AwardInner}>
              <img src={award5} alt="" />
            </div>
            <p>BOLD Awards 2024<br />Boldest Cybersecurity</p>
          </div>

          <div>
            <div className={styles.AwardInner}>
              <img src={award5} alt="" />
            </div>
            <p>BOLD Awards 2024<br />Boldest SaaS</p>
          </div>

          <div>
            <div className={styles.AwardInner}>
              <img src={award6} alt="" />
            </div>
            <p>Go:Tech Awards<br />AI/Machine Learning</p>
          </div>

          <div>
            <div className={styles.AwardInner}>
              <img src={award3} alt="" />
            </div>
            <p>Top 10 CEE Scale-up by Vestbee</p>
          </div>

          <div>
            <div className={styles.AwardInner}>
              <img src={award4} alt="" />
            </div>
            <p>Top 50 UK startups</p>
          </div>

          <div>
            <div className={styles.AwardInner}>
              <img src={award7} alt="" />
            </div>
            <p>The National Cyber Awards<br />AI product of the year 2023</p>
          </div>

          <div>
            <div className={styles.AwardInner}>
              <img src={award8} alt="" />
            </div>
            <p>The National Cyber Awards<br />AI product of the year 2024</p>
          </div>
        </Slider>
      </div>
    </div>
  </Section>
);

export default Awards;
